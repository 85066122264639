@import url('_config.css');
@import url('_base.css');



.FCMessaging {
    color: var(--white);
    font-size: 16px;
    margin-top: 40px;
    text-decoration: none;
    padding: 40px;
    border-radius: 10px;
    background-color: var(--lime_green);
    width: fit-content;
    display: grid;
}

.Toast {
    position: absolute;
    background-color: var(--white);
    color: var(--lime_green);
}
