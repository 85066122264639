:root {
    --lime_green: #8EBB22;
    --white: #ffffff;
    --black: #181818;


    /* Colours */
    --c-brand1: var(--lime_green);    
    --c-brand2: #4FAAA0;
    --c-white: #ffffff;
    --c-black: #181818;
    --c-grey: #dddddd;
    --c-grey-dark: #5E5E5D;
    --c-grey-medium: #AAAAAA;
    --c-grey-light : #F2F2F2;


    /* Fonts */
    --font-main:"Montserrat","Open Sans","Helvetica Neue",sans-serif;


    /* Breakpoints */
    --bp-small: 0;
    --bp-medium: 768px;
    --bp-large:  960px;
    --bp-xl: 1300px;
    --bp-xxl: 1600px;
    --bp-hd: 1920px;
    --bp-xxxl: 2400px;


    
    

}

