@import url('_config.css');
@import url('_base.css');



.default-dashboard-container {


    margin-bottom: 20px;
}


@media screen and (min-width:1000px) {

    .default-dashboard-container {

        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: var(--c-grey-light);
        padding: 13px;
        margin-bottom: 20px;
    }

}



.textspan-greeting {

    font-style: italic;
    /* display: block; */
    /* text-align: left; */
    font-size: 1.1rem;
    margin-right: 30px;
    /* margin-bottom: 30px; */
}


.textspan-budget {

    background: var(--c-brand2);
    font-size: 1.2rem;
    font-weight: 300;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    clear: both;
}



.tutorial-step {
    cursor: pointer;
}






/* Profile */
.profileEntrycontainer {
    padding: 0;
    background: transparent;
    width:100%;
}



.profileContainer {

    align-items: flex-start;
    justify-content: stretch;
    background: var(--c-grey-light);
    background: var(--c-grey-light);
    padding: 15px;
    margin-bottom: 10px;
    position: relative;

}

.profileName {
    font-size: 1.1rem;
    line-height: 1.1;
    margin-right: 20px;
    color: var(--c-brand1);
    font-weight: 600;
    display: block;
    text-align: left;
    margin: 0;


}


@media screen and (min-width: 1024px) {
    .profileName {

        font-size: 1.4rem;
            line-height: 1.2;
    }

}


/* Buttons Allgemein */

.profileButton,
.button-twoFA-content-twoFA-sitekey,
.profile-button {


    background: var(--c-grey-dark);
    font-size: 0.85rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-height:100%;
    border: 1px solid var(--c-grey);
}


.button[title="copy"]:hover,
.profileButton:hover,
.profileInfoButton:hover,
.button-twoFA-content-twoFA-sitekey:hover {

    background: var(--c-black);
    color: var(--c-white);


}



/* + - Button */
.profileName+.profileButton {


    border-radius: 0%;
    background: transparent;
    color: var(--bmstwofa-c-brand1);

    display: flex;
    flex-grow: 0;
    font-size: 2rem;
    line-height: 1;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 7px;
    width: 50px;
    line-height: 1;
    justify-content: center;
    align-items: center;
    border:0;
}


.profileName+.profileButton:hover {
    background: transparent;
    color: var(--bmstwofa-c-black);
}

.button[title="copy"],
.profileInfoButtonPassword,
.profile-button-password {

    background: var(--c-grey);
    font-size: 0.85rem;
    color: var(--c-black);
    width: 60px;
    padding-inline: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-height: 100%;
}




.profileInfoButtonPassword,
.profile-button-password {
    min-width: 90px;
    font-size: 0.75rem;
}



.button[title="copy"]:before,
.profileInfoButtonPassword:before,
.profile-button-password:nth-of-type(1):before,
.profileInfoButtonSave:before,
.profileInfoButtonCancel:before,
.profileInfoButtonDelete:before,
.profileInfoButtonLogin:before {
    font-family: var(--font-icon);
    content: "\f0ea";
    margin-right: 5px;
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
}

.button[title="copy"]:before {

    content: "\f0ea";
    content: "";
    margin-right: 0;



}



.profileInfoButtonPassword:before,
.profile-button-password:nth-of-type(1):before {

    font-family: var(--font-icon);
    content: "\f06e";
}


.profileInfoButtonSave:before {

    font-family: var(--font-icon);
    content: "\f01c";
}

.profileInfoButtonCancel:before {

    font-family: var(--font-icon);
    content: "\f060";
}

.profileInfoButtonCancel {

    background: var(--c-grey-dark);
}


.profileInfoButtonDelete {

    background: var(--c-signal);
}

.profileInfoButtonDelete:before {

    font-family: var(--font-icon);
    content: "\f00d";
}



.profileInfoButtonLogin {

    background: var(--c-brand2);
}

.profileInfoButtonLogin:before {

    font-family: var(--font-icon);
    content: "\f007";
}

.profileInputContainer {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 5px;
}

.profileEdittContaier {
    margin-top: 20px;
}

.profileInputContainer input,
.profile-container input  {

    flex-basis: 50%;
    background: var(--c-white);

}


.profileInput,.profile-input {
   background: var(--c-white);
}


.profileInputContainer .textspan {

    width: 100px;
    text-align: left;
    display: block;

    font-weight: 600;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}



.addProfileContainer {


    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: var(--c-grey-light);
}



/* My Profile Page */


.profile-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 5px;
    width: 100%;

}


.profile-container .profile-container {

    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
}


.profile-container-password {

    justify-content: flex-end;
}

.profile-textspan {

    width: 100px;
    text-align: left;
    display: block;

    font-weight: 600;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}