@import url('fonts/montserrat-webfont/style.css');
@import url('_config.css');
@import url('_base.css');




:root {


    --bmstwofa-c-brand1: #8EBB22;
    --bmstwofa-c-brand2: #4FAAA0;
    --bmstwofa-c-white: #ffffff;
    --bmstwofa-c-black: #181818;
    --bmstwofa-c-grey: #dddddd;
    --bmstwofa-c-grey-medium: #aaaaaa;
    --bmstwofa-c-grey-dark: #5E5E5D;
    --bmstwofa-c-grey-light: #F2F2F2;
}






.bsmstwofa-main,
.TwoFAComponent {
    font-family: "Montserrat", sans-serif !important;
    box-sizing: border-box !important;
}

.bsmstwofa-main *,
.TwoFAComponent * {
    box-sizing: border-box !important;
}


.bsmstwofa-container,
.TwoFAComponent {
    position: relative;
    padding: 10px;
}

.bsmstwofa-captcha-container {
    position: relative;
    display: none
}

.bsmstwofa-twofa-container,
.TwoFAComponent {
    position: relative;
    display: none;
    width: 100%;
    width: 300px !important;
    height: 95px !important;
    min-width: 300px !important;
    min-height: 95px !important;
    max-width: 300px;
    max-height: 95px;
    border: 1px solid var(--bmstwofa-c-grey-medium);
    background: var(--bmstwofa-c-grey-light);
    border-radius: 5px;
    border-bottom: 4px solid var(--bmstwofa-c-brand1);
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    line-height:1;
    align-items: flex-start !important;
}

.TwoFAComponent {

    display: block;
}

.bsmstwofa-logo,
.twofa_logo_container {
    position: absolute;
    right: 24px;
    top: 7px;
    width: 38px;
    height: 38px;
    background-image: url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480" style="width:32px;height:32px"><circle cx="240" cy="239.5" r="237" fill="rgb(143, 187, 34)" stroke="none" /><path fill="rgb(143, 187, 34)" d="M25 468 L63 390 L130 440 Z" /><circle fill="rgb(255, 255, 255)" cx="240" cy="278" r="32" stroke="none" /><path fill="rgb(255, 255, 255)" d="M240 5 L232 250 L248 250 Z" /><path fill="rgb(255, 255, 255)" d="M227 220 L253 220 L257 480 L223 480 Z" /><path fill="rgb(255, 255, 255)" d="M220 320 L260 320 L260 330 L220 330 Z" /></svg>');
}


.twofa_logo_container svg {
    display: none;
}

.bsmstwofa-title,
.twofa_namecontainer {
    position: absolute;
    top: 52px;
    right: 10px;
    font-size: 9.5px;
    font-weight: 600;
    color:var(--bmstwofa-c-black);

}

.bsmstwofa-footer,
.twofa_additionallinks {
    position: absolute;
    bottom: 4px;
    right: 0;
    height: 18px;
    font-size: 8px !important;
    font-weight: 300;
    width: 100%;
    text-align: right;
    padding: 5px 9px;
    font-family:Arial,Helvetica,sans-serif;

}


.twofacomponent_container_captcha {
    z-index:10;
}

.bsmstwofa-footer a:link,
.bsmstwofa-footer a:visited,
.twofa_additionallinks a:link,
.twofa_additionallinks a:visited {
    text-decoration: none;
    color: var(--bmstwofa-c-black);
}


.bsmstwofa-footer a:hover,
.bsmstwofa-footer a:active,
.twofa_additionallinks a:hover,
.twofa_additionallinks a:active {
    text-decoration: underline;
}

.bsmstwofa-error {
    position: absolute;
    display: none;
    margin: 3px;
    color: #f00;
    font-family: Roboto, helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 5px 10px;
}

.bsmstwofa-wait {
    position: absolute;
    width: 200px;
    display: flex;
    height: 70px;
    display: none;
    margin: 0;
    color: var(--bmstwofa-c-brand1);
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    line-height: 16px;
    font-weight: 700;
    padding: 5px 10px;
    background: 20px 20px no-repeat url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480" style="width:32px;height:32px;overflow: visible;"><path stroke-width="20px" stroke-linejoin="round" stroke="rgb(143, 187, 34)" fill="white" d="M150 120 L150 30 L330 30 L330 120 L150 360 L150 450 L330 450 L330 360 L150 120 Z"><animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 240 240" to="360 240 240" dur="10s" repeatCount="indefinite"/></path></svg>');
    background-size: 32px 32px;
    background-position: center 25px;
}


.bsmstwofa-phone-text,
.bsmstwofa-code-text,
.TwoFAComponent_Label_EnterPhonenumber,
.descr-label  {
    font-weight: 600;
    font-size: 11.6px;
    color: var(--bmstwofa-c-brand1);
    padding: 10px 0 0 9px;
    height: 32px;
    overflow: hidden;
    box-sizing: border-box;
    top: 0;
    display: block;
    width: 280px;

}



.bsmstwofa-phone-text+br,
.bsmstwofa-code-text+br {

    display: none;

}

.bsmstwofa-phone-input,
.TwoFAComponent_Input {
    box-shadow: 0;
    background: #fff;
    color: var(--bmstwofa-c-black);
    margin: 0;
    border: 1px solid #888;
    margin-left: 8px;
    padding: 6px;
    width: 194px;
    font-size: 13px;


}



.bsmstwofa-phone-input:focus,
.bsmstwofa-phone-input:focus-visible,
.TwoFAComponent_phonenumber_input:focus,
.TwoFAComponent_phonenumber_input:focus-visible {

    outline: none;
    border: 1px solid var(--bmstwofa-c-brand1);
}



.bsmstwofa-code-request,
.bsmstwofa-code-verify,
.TwoFAComponent_Button {
    display: block;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: var(--bmstwofa-c-brand1);
    color: black;
    margin: 0;
    padding: 4px !important;
    width: 83px;
    margin-left: 8px;
    margin-top: 3px;
    font-size: 11px;
    line-height: 1;
    font-weight: 500;
    color: var(--bmstwofa-c-white);
    position: relative;
    z-index: 1;
}

.bsmstwofa-code {}

.bsmstwofa-code-digit {
    border: 1px solid var(--bmstwofa-c-grey);
    border-radius: 3px;
    box-shadow: none;
    background: var(--bmstwofa-c-white);
    color: var(--bmstwofa-c-black);
    margin: 0;
    padding: 0;
    width: 28px;
    height: 25px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    padding: 5px;

    margin-bottom: 3px;
}


input.bsmstwofa-code-digit:nth-of-type(1) {
    margin-left: 8px;
}



/* Neuen Code anfordern */
.TwoFAComponent_Label_resetLabel {
    display:none;
}


.TwoFAComponent_Button_Reset {

    position: absolute;
    bottom: -4px;
    left: 90px;
    background: var(--bmstwofa-c-grey-dark);


}