@import url('_config.css');
@import url('_base.css');


.balance > div {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.balance-description2,.balance-insertedValue {

    max-width: none;
    width:100%;
    margin:20px 0;
    color:var(--c-brand2);
    padding:20px;
    background-color: var(--c-grey-light);


}


.textlabel.balance-currencyhint {
        font-size: 0.7rem;
}

.paypal-button-label_1, .invoice-button-label_1 {
    color:var(--c-brand1);
    font-weight: 600;
}



.paypal-button-label_2, .invoice-button-label_2 {
    color:var(--c-black);
}





.balance .campaign-payment-button-container {
    display: flex;
  justify-content:flex-start;
  
}


.balance .campaign-payment-button-container hr {
  
  width:100%;
  margin:20px 0;
  height:0;
  border-top:1px solid var(--c-grey);
}




.balance-description {

    width:100%;
}
