@import url('_config.css');
@import url('_base.css');



.sendSMS > div {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
}



.phonenumber-inserted {
    max-width: none;
    width:100%;
    margin:20px 0;
    color:var(--c-brand2);
    padding:20px;
    background-color: var(--c-grey-light);
}
