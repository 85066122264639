@import url('_config.css');
@import url('_base.css');



.login,
.register {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--c-black);
    background-color: var(--c-white);
    padding: 30px 50px;
    border-radius: 5px;
    box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    margin-inline: auto;
}



.login-inputs-container>div,
.register>div {

    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;


}

@media screen and (min-width: 480px) {

    .login-inputs-container>div,
    .register>div {

        flex-wrap: nowrap;


    }
}


.login-button-container {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


}


.register-button-container .submitbutton,
.login-button-container .submitbutton {
    margin-right: 20px;
}


/* Feedback */

.register-feedback-container {


    flex-wrap: wrap !important;
}

.feedback-text {
    flex-basis: 100%;
    background: var(--c-white);
    border:1px solid var(--c-brand1);
    color: var(--c-black);
    padding: 5px;
    border-radius:4px;
    margin: 5px 0;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    text-align: left;
}


.feedback-text:before {

    color:var(--c-black);
    content:"";
    flex-basis:30px;
    flex-shrink: 0;
    flex-grow: 0;

    font-family: var(--font-icon);
    content: "\f071";

    margin-inline: 10px;





}





/* Ostern */

.special-event-container {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background:var(--c-grey-light);
    padding:15px;
    border-radius:10px;
    margin-bottom: 20px;
}


.special-event-textspan {
    text-align: left;
    width:100%;
    


}


@media screen and (min-width: 1024px) {

   .special-event-textspan {

    flex-basis:70%;
    }

}



.special-event-header {

    width:100%;
    text-align: left;
    font-size: 1.2rem;
}


@media screen and (min-width: 1024px) {

   .special-event-header {

    flex-basis:25%;
    font-size: 0.9rem;

    }

}
