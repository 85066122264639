@import url('_config.css');
@import url('_base.css');



.twofaHeader {
    font-family: var(--font-main), sans-serif !important;
    box-sizing: border-box !important;
    color: var(--c-black);
    font-size: 16px;
    margin-top: 0px;
    text-decoration: none;
    padding: 40px;
    background-color: var(--c-grey-light);
    margin-left: 0;
    margin-right: 0;
}
.hidden {
    display: none;
}