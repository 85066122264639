@import url('_config.css');
@import url('_base.css');


body {
    font-family: var(--font-main), sans-serif;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    background-image: url("../images/BackgroundImage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

span {
    text-align: center;
}

h1 {
    text-align: center;
}

a {
    cursor: pointer;
}



.center,.App {
    position: relative;
     margin: 70px auto;
    max-width: calc(100vw - 40px);
}



@media screen and (min-width: 480px) {

    .center,.App {


         margin: 70px auto;


    }

}

@media screen and (min-width: 768px) {

    .center,.App {


        margin: 70px auto;
        max-width: calc(100vw - 120px);

    }

}


@media screen and (min-width: 1100px) {

    .center,.App {
       
     
       
        max-width: 1000px;
        width: auto;
    }

}



@media screen and (min-width: 1920px) {
    .center,.App {
        max-width: 1200px;
    }


}


.center-localy {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.inner-content {
    display: flex;
    float: right;
    width: -moz-available;
    max-height: 100vh;
}

.fill-space {
    display: flex;
    justify-content: center;
    width: 100%;

}

.App {
    display: flex;
    align-items: center;
}




/* Content Container */


.dashboard,
.content-container,
.reports,
.twoFA-content-container {
    margin-top: 40px;
    text-decoration: none;
    /*min-width: 300px;*/
    color: var(--c-black);
    background-color: var(--c-white);
    padding: 20px 20px;
    border-radius: 5px;
    /*width: fit-content;*/
    box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.3);

    margin: 0 auto;
    /*max-width: 100vw;*/




}

@media screen and (min-width: 768px) {

    .dashboard,
    .content-container,
    .reports,
    .twoFA-content-container {
        padding: 30px 50px;

    }


}

@media screen and (min-width: 1100px) {

    .dashboard,
    .content-container,
    .reports,
    .twoFA-content-container {
        padding: 30px 50px;
        /*max-width: 800px;*/

            width: 80vw;
            max-width: 100%;
    
    }


}


.twoFA-content-container .twoFA-content-container {
    padding: 0;
    box-shadow: none;
    border-top: 1px solid var(--c-grey);
    padding: 20px 0;

}


/* Sub Containers */
.twoFA-content-container2, [class*=".twoFA-content-container-"] {

    display: flex;
} 





/* Sidebar Logo */


.sidebar [alt*=logo] {
    position: fixed;
    left:20px;
    top:20px;
    width: 50px;
     display: block;
    
}


@media screen and (min-width:1100px) {
    .sidebar [alt*=logo] {
        
        width: 50px;
        z-index:100;
    }



}




/* Sidebar  */




/* New Sidebar with Hamburger Icon */
.sidebar {
    opacity: 1;
    position: fixed;
    right:-100%;
    top:0;
    width:100%;
    z-index: 12;
    
}


@media screen and (min-width:600px) {


    .sidebar {
        
       
        max-width:300px;

        /*height: 50px;*/
        
    }
}







/* Sidebar Items Menu Box */

.sidebar_items {
    background: #fff;
    width:100vw;
    right:-100%;
    top:0;
    position: fixed;
    /*display: flex;
    flex-direction: column;
    flex-wrap: wrap;*/
    height:100vh;
    padding:20px;
    opacity:0;
    transition: all 0.5s ease-in-out;
    box-shadow: -2px 0 25px rgba(0,0,0,0.0);
    
    
}


@media screen and (min-width:600px) {


    .sidebar_items {
        
       
        max-width:300px;

        /*height: 50px;*/
        
    }
}


@media screen and (min-width:1920px) {


    .sidebar_items {
        
       
        max-width:300px;

        right:auto;
        opacity:1;
        left:0;
        padding-top:100px;

        /*height: 50px;*/
        
    }
}



/*.sidebar_items>* {
    order: 2;
    display: flex;
}*/

.sidebar_items a {
  
    font-size: 1.3rem;
    width:100%;
    display: block;

    font-size: 1.3rem;
    font-weight: 500;

    text-decoration: none;
    color: #444;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
}

.sidebar_items a:hover {
    color: #117964;
    transition: color 0.3s ease-in-out;
}







/* Sidebar Checkbox + Hamburger */


.sidebar input[type="checkbox"] {
    position: fixed;
    display: block;
    height: 55px;
    width: 55px;
    top: 15px;
    right: 15px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}





/* Sidebar Transformations with checkbox */

.sidebar input[type="checkbox"]:checked~.sidebar_items {
    right: 0;
     box-shadow: -2px 0 25px rgba(0,0,0,0.2);
    opacity:1;
    transition: all 0.5s ease-in-out;
}






/**
  Hamburger Lines
**/
.hamburger-lines {
  position: fixed;
  display: block;
  top: 15px;
  right: 15px;
  cursor: pointer;
 
  z-index: 9999;
  padding: 0;
  width: 55px;
  height: 55px;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  z-index: 2;

  background-color: var(--c-brand1);
    padding:10px;

  
}


@media screen and (min-width:1024px) {


    .hamburger-lines {
        
       
     right:20px;
        
    }
}


.hamburger-lines .line {
  position: relative;
  width: 35px;
  height: 2px;
  border-radius: 0px;
  background-color: #fff;
  margin-top: 6px;
  transition: all 0.3s ease-in-out;
}
.hamburger-lines .line:first-child {
    margin-top:0;
  
}


/**
Animations
**/
.hamburger-check:checked + .hamburger-lines .top {
  transform: rotate(-45deg);
  background-color: #fff;
  margin-top: 10px;
}
.hamburger-check:checked + .hamburger-lines .bottom {
  opacity: 0;
  background-color: #fff;
  transform: rotate(45deg);
}
.hamburger-check:checked + .hamburger-lines .mid {
  transform: rotate(45deg);
  background-color: #fff;
  margin-top: -4px; /* has to be the height value */
}





/* Hide Hamburger Menu and Checkbox in HD+ Sizes */


@media screen and (min-width:1920px) {


    .sidebar input[type="checkbox"],
    .hamburger-lines {
        
       
      display:none !important;
        pointer-events:none;
        
    }
}



/* Sidebar Containers */

.language-container,
.language-conatiner {
    /* display: none; */
    display: inline;
}


.versions-container,
.versions-conatiner {
    font-size: 11px;
    font-weight: 600;
    position: absolute;
    bottom: 20px;
    
   


}



@media screen and (min-width:1100px) {

    .versions-container,
    .versions-conatiner {
        display: block;
        right: auto;


    }


}


/* Language Selector */ 


.language-select {
    padding:3px 7px;
}

@media screen and (min-width:1920px) {
    .language-select {
    margin-top:0;
    position: absolute;
    right: 20px;
    top: 30px;
    }

}

/* Sidebar Links */


.links-container {
    margin: 0;
     margin-top: 40px;
    
}


@media screen and (min-width:1024px) {

    .links-container {
        margin-top: 50px;
        margin-bottom: 100px;

    }

}

.links-container a:link,
.links-container a:visited {

    display: block;
    font-size: 1rem;
    padding: 10px 5px;
    color: var(--c-black);
    width: 100%;
    border: 0;
     border-top: 1px solid var(--c-grey-medium);
        padding: 10px 0;

    text-decoration: none;

}




.links-container a:hover, .links-container a:active, .links-container a.active, .links-container a:focus {
    text-decoration: none;
    color: var(--c-brand1);
}




.links-container .componentlink:before {


    font-family: var(--font-icon);
    content: "\f0ea";
    margin-right: 14px;
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
    width:16px;
    display: inline-block;
    text-align: center;
    color:var(--c-grey-medium);


}

.links-container .componentlink:focus:before {


   
    color:var(--c-brand);


}


.links-container .componentlink.link-dashboard:before {


    content: "\f015";


}


.links-container .componentlink.link-reports:before {


    content: "\f15b";


}


.links-container .componentlink.link-sendsms:before {


    content: "\f061";


}



.links-container .componentlink.link-profile:before {


    content: "\f2bd";


}


.links-container .componentlink.link-invoices:before {


    content: "\f15c";


}


.links-container .componentlink.link-balance:before {


    content: "\f1ec";


}



.links-container .componentlink.link-account-manager:before {


    content: "\f234";


}



.links-container .componentlink.link-twofa:before {


    content: "\f023";


}


.links-container .componentlink.link-keygenerator:before {

    content: "\f084";

}




/* Logout Button */

.links-container .button-logout {



    margin-top: 20px;
}

.links-container .button-logout:before {
    font-family: var(--font-icon);
    margin-right: 15px;
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
   
   
    color:var(--c-grey-medium);

    content: "\f011";
    color:var(--c-white);


}
