@import url('_config.css');
@import url('_base.css');


.reports {
    /*display: grid;
    columns: 2;*/
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    /*color: var(--white);
    font-size: 16px;
    margin-top: 40px;
    margin-left: 50px;
    text-decoration: none;
    padding: 40px;
    border-radius: 10px;
    background-color: var(--lime_green);*/

    /*width: fit-content; */
}
.tab-nav {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    text-decoration: none;
    width: fit-content; 
    max-width: auto;
    text-align: center;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}
.tab_inactive {
    padding: 10px;
    color: var(--white);
    background-color: var(--lime_green);  
}
.tab_active {
    padding: 10px;
    color: var(--lime_green);
    border-radius: 5px;
    background-color: var(--white);
}

.send-statistic {
    display: grid;
    grid-column: 1;
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: var(--white);
}


@media screen and (max-width:1024px) {
    .send-statistic {

        overflow: scroll;
    }
}
.header {
    margin: 10px;
    color: var(--lime_green);
}
.header-reports {
    width:100%;
}


.dropdown_menu {
    align-self: center;
    align-items: center;
}
.tableheader {
    color: var(--lime_green);
    background-color: var(--white);
    margin: 0px;
}

/* .dx-datagrid{
    padding: 5px;
    border-radius: 5px;
} */