@import url('_config.css');
@import url('_base.css');


/*.twoFA-content-container {
    width: 100%;
}*/

.hidden {
    display: none;
}

/* center the content to the page */
.twoFA-inner-content-container {
    /*background-color: var(--lime_green); */

    /*text-align: center;*/

}




.twoFA-button-container {

    margin-top:30px;
}