@import url('_config.css');
@import url('_base.css');


.invoices {
   
    display: grid;
}

/* Datagrid styling */

.dx-header-row{
    background-color: var(--white);
    color: var(--lime_green);
}
.dx-data-row:hover{
    cursor: pointer;
    background-color: var(--white);
    color: var(--lime_green);
}