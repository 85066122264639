@import url('_config.css');
@import url('_base.css');



.keyspan {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    color: var(--c-black);
    background-color: var(--c-white);
    border-radius: 10px;
    padding: 10px;
    
}
.keygen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--c-brand1);
    color: var(--c-black);
    padding: 20px;
    border-radius: 10px;
}